import axios from 'axios';
import JSONbigString from 'json-bigint';
import {API_URL, IS_DEV} from './constants';
import {incAndGet} from './counter';
import {isDef} from './utils';

const JSONbigStringInstance = JSONbigString({storeAsString: true});

const request = async (url, body = null) => {
    const requestId = incAndGet() + '-' + (Date.now() % 1000000).toString(36).toUpperCase();
    const timeStart = Date.now();

    const config = {
        timeout: 60000,
        // validateStatus: (status) => {
        //     console.log("Validating status " + status);
        //     return status < 500;
        // },
        transformResponse: [
            function (data) {
                if (!isDef(data) || data.trim().length === 0) {
                    return null;
                }
                // OK empty body
                try {
                    // Parses numbers greater than what javascript can handle as strings
                    return JSONbigStringInstance.parse(data);
                    // eslint-disable-next-line no-empty
                } catch (e) {}
                return data;
            },
        ],
    };

    try {
        let response;
        if (isDef(body)) {
            if (IS_DEV === true) {
                console.log('Request [' + requestId + '] POST ' + url, body);
            }
            response = await axios.post(url, body, config);
        } else {
            if (IS_DEV === true) {
                console.log('Request [' + requestId + '] GET ' + url);
            }
            response = await axios.get(url, config);
        }

        const timeEnd = Date.now();

        if (IS_DEV === true) {
            console.log('Response [' + requestId + '] ' + url + ' (' + (timeEnd - timeStart) + ' ms)', {
                data: response.data,
                status: response.status,
            });
        }
        return response.data;
    } catch (e) {
        if (isDef(e.response)) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(e.response.data);
            // console.log(e.response.status);
            // console.log(e.response.headers);

            // console.error("Error from server", e.response, e.config);
            if (e.response.data.status === 401) {
                // sign out
            } else if (e.response.data.status === 503) {
                if (IS_DEV === true) {
                    console.log('Network error [' + requestId + ']');
                }
            }
            if (IS_DEV === true) {
                console.log('Rejected response [' + requestId + '] ' + url, {
                    responseBody: e.response.data,
                    requestBody: body,
                });
            }
            return Promise.reject(e.response.data);
        } else if (isDef(e.request)) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(e.request);

            // console.error("Error when axios tried to request " + url, e.request, e.config, e.toJSON().message);

            if (IS_DEV === true) {
                console.log('Rejected request [' + requestId + '] ' + url, {
                    status: 503,
                    message: e.toJSON().message,
                    url: e.config.url,
                    method: e.config.method,
                    body: body,
                });
            }
            return Promise.reject({
                status: 503,
                message: e.toJSON().message,
                url: e.config.url,
                method: e.config.method,
            });
        }
        // Something happened in setting up the request that triggered an Error
        console.error('Error when setting up axios request [' + requestId + '] ' + url, {
            message: e.message,
            config: e.config,
            body: body,
        });
        return Promise.reject({
            message: e.message,
        });
    }
};

export const requestLink = async (advertiserId, language = null) => {
    if (!isDef(advertiserId) || advertiserId.toString().trim() === '') {
        return Promise.reject({
            status: 404,
            message: 'Missing required advertiserId',
        });
    }
    return await request(API_URL + '/ptn/link/' + advertiserId + (isDef(language) ? '/' + language : ''));
};

export const requestWidget = async (advertiserId, language = null) => {
    if (!isDef(advertiserId) || advertiserId.toString().trim() === '') {
        return Promise.reject({
            status: 404,
            message: 'Missing required advertiserId',
        });
    }
    return await request(
        API_URL + '/ptn/widget/' + advertiserId + (isDef(language) ? '/' + language : '') + '?t=' + Date.now()
    );
};

export const requestPortal = async (jwt, language = null, preview = null, fallback) => {
    if (!isDef(jwt) || jwt.toString().trim() === '') {
        return Promise.reject({
            status: 404,
            message: 'Missing required jwt',
        });
    }
    return await request(
        API_URL +
            '/ptn/portal/' +
            jwt +
            (isDef(language) ? '/' + language : '') +
            (isDef(preview) ? '?preview=' + preview : '?') +
            (isDef(fallback) ? '&fallback=' + fallback : '')
    );
};

export const requestCoupon = async (advertiserId, jwt, language = null, preview, share, channelId) => {
    if (!isDef(advertiserId) || advertiserId.toString().trim() === '') {
        return Promise.reject({
            status: 404,
            message: 'Missing required advertiserId',
        });
    }
    if (!isDef(jwt)) {
        return Promise.reject({
            status: 404,
            message: 'Missing required jwt',
        });
    }
    return await request(API_URL + '/ptn/coupon/' + advertiserId + '/' + jwt + (isDef(language) ? '/' + language : '') + '?' + (isDef(preview) ? 'preview=' + preview : '') + (isDef(share) ? '&share=' + share : '')
        + (isDef(channelId) ? '&channelId=' + channelId : ''));
};

export const requestOffer = async (jwt, share, channelId) => {
    if (!isDef(jwt) || jwt.toString().trim() === '') {
        return Promise.reject({
            status: 404,
            message: 'Missing required advertiserId',
        });
    }
    return await request(API_URL + '/ptn/offer/' + jwt + '?' + (share ? '&share=true' : '') + (channelId ? '&channelId=' + channelId : ''));
};

export const sendBeacon = url => {
    if (isDef(url) && url.length > 0) {
        var cleanUrl = url.replace(/&url=.*$/, '');
        if (!navigator.sendBeacon) {
            var imgEl = document.createElement('img');
            imgEl.src = cleanUrl;
            imgEl.width = 1;
            imgEl.height = 1;
            imgEl.style.cssText = 'display:none';
            document.body.appendChild(imgEl);
            console.log('Triggered event: ' + cleanUrl + ' using image element.');
        } else {
            navigator.sendBeacon(cleanUrl);
            console.log('Triggered event: ' + cleanUrl + ' using beacon.');
        }
    }
};

export const requestPortalLink = async (advertiserId, language = null, preview = null) => {
    if (!isDef(advertiserId) || advertiserId.toString().trim() === '') {
        return Promise.reject({
            status: 404,
            message: 'Missing required advertiserId',
        });
    }
    return await request(
        API_URL +
            '/ptn/portalLink/' +
            advertiserId +
            (isDef(language) ? '/' + language + '?' : '?') +
            (isDef(preview) ? 'preview=' + preview + '&' : '') +
            't=' +
            Date.now()
    );
};

export const requestFallbackPortalLink = async (channelId, programId) => {
    if (!isDef(channelId) || channelId.toString().trim() === '') {
        return Promise.reject({
            status: 404,
            message: 'Missing required channelId'
        });
    }
    return await request(API_URL + '/ptn/link/fallback/' + channelId + '/' + programId);
};

export const requestAdvertiserPortal = async (id, newChannelId) => {
    if (!isDef(id) || id.toString().trim() === '') {
        return Promise.reject({
            status: 404,
            message: 'Missing required id',
        });
    }
    return await request(API_URL + '/ptn/portal?advertiserId=' + id + (isDef(newChannelId) ? '&newChannelId=' + newChannelId : ''));
};

export const requestAdvertiserPortalCoupon = async (advertiserId, portalAdvertiserId, lang, preview, share, newChannelId) => {
    if (!isDef(advertiserId) || advertiserId.toString().trim() === '') {
        return Promise.reject({
            status: 404,
            message: 'Missing required advertiserId',
        });
    }
    if (!isDef(portalAdvertiserId)) {
        return Promise.reject({
            status: 404,
            message: 'Missing required portalAdvertiserId',
        });
    }
    return await request(
        API_URL +
            '/ptn/coupon?couponAdvertiserId=' +
            advertiserId +
            '&advertiserId=' +
            portalAdvertiserId +
            (isDef(lang) ? '&lang' + lang : '') +
            (isDef(preview) ? '&preview=' + preview : '') +
            (isDef(share) ? '&share=' + share : '') +
            (isDef(newChannelId) ? '&newChannelId=' + newChannelId : '')
    );
};

export const requestOfferSharePortal = async (uuid) => {
    return await request(API_URL + '/ptn/portal/offershare?uuid=' + uuid);
};
